import React from 'react';
import ReactDOM from 'react-dom/client';
import env from 'react-dotenv';
import './index.css';
import App from './App';
import startRUM from './analytics/rum';

const root = ReactDOM.createRoot(document.getElementById('root')!);
root.render(<App />);

startRUM(env.ENV_NAME || '');
