import {useState, useEffect, useRef} from 'react';
import env from 'react-dotenv';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import useWebSocket from 'react-use-websocket';
import getImageLoader from './imageLoader';
import './index.css';

export interface MessageBody {
    consoleMessageDisplay: boolean;
    consoleMessage: string;
    eventTime: string;
    environment: {
        account: string;
        region: string;
    };
    imagename: string;
    fontsize: number;
    fontcolor: string;
}

const EventLogs = () => {
    const socketUrl = 'wss://webservice.' + env.DOMAIN_NAME;
    const envName = env.ENV_NAME;
    const defaultDesign = env.ENV_NAME + "-normal"

    const listRef = useRef<any>(null);
    const [messageHistory, setMessageHistory] = useState<MessageBody[]>([]);
    const [imageName, setImageName] = useState<string>(defaultDesign);

    const {lastJsonMessage} = useWebSocket<MessageBody>(socketUrl, {
        onOpen: (event) => console.log('Websocket Connection Opened: ', event),
        onClose: (event) => console.log('Websocket Connection Closed: ', event),
        onError: (event) => console.error('Websocket Connection Error: ', event),
        retryOnError: true,
        //Will attempt to reconnect on all close events, such as server shutting down
        shouldReconnect: (closeEvent) => {
            console.log('Received Close Event, Attempting to reconnect', closeEvent);
            return true;
        },
    });

    useEffect(() => {
        if (lastJsonMessage !== null) {
            setMessageHistory((prev) => {
                if (prev.length >= 100) prev.shift();

                return prev.concat(lastJsonMessage);
            });

            if (lastJsonMessage.imagename && lastJsonMessage.imagename.trimEnd() !== '') {
                setImageName(lastJsonMessage.imagename);
            }
        }
    }, [lastJsonMessage]);

    useEffect(() => {
        listRef.current?.lastElementChild?.scrollIntoView();
    }, [messageHistory]);

    return (
        <Container fluid>
            <Row>
                <Col md={12} className="logs-table-header p-2 mb-1">
                    Architecture Design
                </Col>
            </Row>
            <Row>
                <Col md={12}>
                    <Image
                        src={getImageLoader(imageName)}
                        className="image mx-auto d-block"
                    />
                </Col>
            </Row>
            <Row>
                <Col md={12} className="logs-table-header p-2 mb-1">
                    Event Logs
                </Col>
            </Row>
            <Row>
                <Col md={12} style={{height: "240px"}}>
                    <div ref={listRef} className="logs bg-dark w-100 p-2 overflow-scroll">
                        {messageHistory.map((message, index) => (
                            <span
                                key={`log-${index}`}
                                className={
                                    message.fontcolor === 'green'
                                        ? 'log-success'
                                        : message.fontcolor === 'red'
                                            ? 'log-error'
                                            : 'log-normal'
                                } style={{
                                fontSize:
                                    message.fontsize && message.fontsize > 0
                                        ? `${message.fontsize}px`
                                        : '1rem',
                            }}
                            >
                {`${message.eventTime} - ${message.consoleMessage}`}
              </span>
                        ))}
                        <div className="cursorBlink"></div>
                    </div>
                </Col>
            </Row>

        </Container>
    );
};

export default EventLogs;
