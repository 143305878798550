import env from 'react-dotenv';
import { AwsRum } from 'aws-rum-web';

const rumConfig = {
  config: {
    sessionSampleRate: 0,
    guestRoleArn: env.RUM_GUESTROLE_ARN as string,
    identityPoolId: env.RUM_IDENTITYPOOL_ID as string,
    endpoint: env.RUM_ENDPOINT as string,
    telemetries: [],
    allowCookies: true,
    enableXRay: true,
  },

  APPLICATION_ID: env.RUM_APPMONITOR_ID as string,
  APPLICATION_VERSION: env.GitVersion_SemVer as string,
  APPLICATION_REGION: env.AWS_PRIMARY_REGION as string,
};

export default function getRumConfig(envName: string) {
  try {
    const awsRum = new AwsRum(
      rumConfig.APPLICATION_ID,
      rumConfig.APPLICATION_VERSION,
      rumConfig.APPLICATION_REGION,
      rumConfig.config
    );

    if (awsRum) {
      console.log('AWS RUM is enabled for Env: ', envName);
    } else {
      console.log('AWS RUM is NOT enabled for Env: ', envName);
    }
  } catch (error) {
    // Ignore Errors
    console.warn(
      `Failed to enable AWS RUM for Env: ${envName}. Error: `,
      error
    );
  }
};
